import React from 'react';
import { BrowserRouter, Switch, Route, Redirect } from 'react-router-dom';
import Estacao from './pages/public/Estacao';
import Cuco from './pages/public/Cuco';
import Main from './pages/public/Main';
import Admin from './pages/admin/Admin';
import Login from './pages/admin/Login';
import Auth from './services/AuthService';

const PrivateRoute = ({ component: Component, ...rest }) => (
    <Route
      {...rest}
      render={(props) => {
        const base_url = process.env.PUBLIC_URL || "";
        const user = Auth.isLogged();
        if (!user) {
          return (
            <Redirect
              to={base_url+"/admin/login"}
            />
          );
        }
        return <Component match={rest.computedMatch}/>;
      }}
    />
  );


function Routes(){
    const base_url = process.env.PUBLIC_URL || "";
    return(
        <>
            <BrowserRouter>
            <Switch>
                <Route path={base_url+"/"} exact={true} component={Main} />
                <Route path={base_url+"/estacao-cuco-maluko"} exact={true} component={Estacao} />
                <Route path={base_url + "/cuco-maluko"} exact={true} component={Cuco} />
                <Route path={base_url+"/admin/login"} exact component={Login} />
                <PrivateRoute path={base_url+"/admin/:sectionId"} exact component={Admin} />
                <PrivateRoute path={base_url+"/admin"} exact component={Admin} />
            </Switch>
            </BrowserRouter>
        </>
    );
}

export default Routes;