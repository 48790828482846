import React, {useEffect, useState, useRef} from 'react';
import { Box, Image } from './styles';

const Slider = ({images}) => {
  const boxRef = useRef();
  const imageRefs = useRef([]);
  const [height, setHeight ] = useState();
  const intervalRef = useRef();
  const timeoutRef = useRef();

  const sliderSpeed = 500;

  useEffect(() => {
    setHeight(boxRef.current.offsetWidth*2/3);
    if(images.length > 1) {
      intervalRef.current = setInterval(() => {
          imageRefs.current[0].style.marginLeft = `-${boxRef.current.offsetWidth}px`;
          timeoutRef.current = setTimeout(() => {
            const firstImage = imageRefs.current[0].cloneNode();
            imageRefs.current[0].remove();
            imageRefs.current.splice(0, 1);
            firstImage.style.marginLeft = `0px`;
            boxRef.current.appendChild(firstImage);
            imageRefs.current.push(firstImage);
          }, sliderSpeed)
      }, 5000)
    }
    return () => {
      clearInterval(intervalRef.current);
      clearTimeout(timeoutRef.current);
    }
  }, [images])

  return(
    <>
      <Box ref={boxRef} height={height}>
        {images.map((image, index) => <Image id={`image${index+1}`} sliderSpeed={sliderSpeed} ref={el => imageRefs.current[index] = el} src={image} />)}
      </Box>
    </>
  );
}

export default Slider;