import React, {useContext } from 'react';
import WidthStoreContext from '../../stores/WidthStore';
import { screenSizes } from '../../settings';
import { Container, Box, BoxText, Title, Text } from './styles';
import 'react-awesome-slider/dist/styles.css';
import Slider from '../Slider';
import { MenuContext } from '../../App';

function Section(props){
    const width = useContext(WidthStoreContext);
    const menuRefs = useContext(MenuContext);
    const {section} = props;

    function renderImage(section){
        if(section.images && section.images.length > 0){ 
           return(<Slider images={section.images} />);
        }
        else return(<></>);
    }

    const renderFirst = !props.left || width < screenSizes.xsmall;
    return(
        <>
            <Container ref={el => menuRefs.current[props.id - 1] = el} id={props.id} color={section.color}>
                { renderFirst && renderImage(section) }
                <Box>
                    <BoxText>
                        <Title>{section.title}</Title>
                        <Text>{section.text}</Text>
                    </BoxText>
                </Box>
                { !renderFirst && renderImage(section) }
            </Container>
        </>
    );
}

export default Section;