import React, {useState, useEffect} from 'react';
import Auth from '../../services/AuthService';
import styled from 'styled-components';
import {Redirect} from 'react-router-dom';

const Button = styled.button`
    background-color: rgba(93, 108, 162, 255);
    padding: 10px 40px;
    margin-bottom: 20px;
    color: white;
    border: none;
    font-size: 18px;
    cursor: pointer;
`;

const Login = () => {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);
  const base_url = process.env.PUBLIC_URL || "";

  useEffect(() => {
    const user = Auth.isLogged();
    if(user) setSuccess(true);
  }, [])

  const onSubmit = async (e) => {
    e.preventDefault();

    setError(false);
    setSuccess(false);

    const res = await Auth.logInUser(login, password);

    if(!res) setError(true);
    else setSuccess(true);
  }

  return(
    <div style={{width: '100%', height: "100vh", backgroundColor: "#3498db", display: "flex", alignItems: "center", justifyContent:"center"}}>

      <div style={{padding: 40 , backgroundColor: 'white', textAlign: "center", display: "flex", flexDirection:"column"}}>

        <h3>Login</h3>
        <input type="text" placeholder="Login" value={login} onChange={(e) => setLogin(e.target.value)} style={{padding: 10, marginBottom: 10, border: '1px solid #ddd', width: 200}}></input>
        <input type="password" placeholder="Senha" value={password} onChange={(e) => setPassword(e.target.value)} style={{padding: 10,  marginBottom: 10, border: '1px solid #ddd', width: 200}}></input>
        <Button onClick={onSubmit}>Acessar</Button>

        {error && <span style={{color: 'red', marginTop: 20}}>Erro ao logar.</span>}
        {success && <Redirect to={base_url+"/admin"} />}
      </div>
      
    </div>
  );
}

export default Login;