import React, {createContext, useRef} from 'react';
import { createGlobalStyle } from "styled-components";
import WidthStoreContext  from './stores/WidthStore';
import useWindowWidth from 'use-window-width';
import Routes from './routes';

const GlobalStyles = createGlobalStyle`
  body {
    @import url('https://fonts.googleapis.com/css?family=Roboto');
    font-family: 'Roboto', sans-serif;
  }
`;

export const MenuContext = createContext();

const App = ()=>{
  const width = useWindowWidth();
  const menuRefs = useRef([]);
  return (
    <>
      <GlobalStyles/>
      <WidthStoreContext.Provider value={width}>
        <MenuContext.Provider value={menuRefs}>
          <Routes />
        </MenuContext.Provider>
      </WidthStoreContext.Provider>
    </>
  );
}

export default App;