import axios from 'axios';

const isLogged = () => {
  let user = localStorage.getItem('userCuco');
  if(!user) return false;

  user = JSON.parse(user);
  axios.defaults.headers.common.Authorization = `Bearer ${user.token}`;

  return user;
}

const logInUser = async (userLogin, password) => {
  try{
    const res = await axios.post("/auth/login", {login: userLogin, password});
    const {token} = res.data;
    localStorage.setItem('userCuco', JSON.stringify({token}));
    return true;
  }
  catch(error) {
    console.log(error);
    localStorage.removeItem('userCuco');
    return false;
  }
}

const logOut = () => {
  localStorage.removeItem('userCuco');
  localStorage.clear();
  axios.defaults.headers.common.Authorization = null;
}

// eslint-disable-next-line import/no-anonymous-default-export
export default {isLogged, logInUser, logOut};