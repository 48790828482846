import React from 'react';
import Header from '../../components/Header';
import Content from '../../components/Content';

function Cuco(){
    return(
        <>
        <Header op={'cuco'}></Header>
        <Content op={"cuco"}></Content>
        </>
    );
}

export default Cuco;