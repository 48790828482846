import React from 'react';
import Header from '../../components/Header';
import Content from '../../components/Content';

function Estacao(){
    return(
        <>
        <Header op="estacao"></Header>
        <Content op={"estacao"}></Content>
        </>
    );
}

export default Estacao;