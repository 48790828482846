import styled from 'styled-components';

export const Box = styled.div`
  flex:1;
  paddding:0;
  overflow: hidden;
  display: flex;
  height: ${props => `${props.height}px` || 'auto'}
`;

export const Image = styled.img`
  width: 100%;
  height: auto;
  transition: all ease-in ${props => props.sliderSpeed || 500}ms;
`;