/* eslint-disable jsx-a11y/anchor-is-valid */
import React, {useState} from 'react';
import SectionsAdd from '../../components/SectionsAdd';
import SectionsList from '../../components/SectionsList';
import Auth from '../../services/AuthService';
import {Redirect} from 'react-router-dom';

function Admin(props){
    const [redirect, setRedirect] = useState(false);
    const base_url = process.env.PUBLIC_URL || "";
    const logOut = (e) => {
        e.preventDefault();
        Auth.logOut();
        setRedirect(true);
     }

    return(
        <>
            <div style={{backgroundColor: "#3498db", padding: 10}}>
                <a style={{color: 'white'}} href="#" onClick={logOut}>Logout</a>
            </div>
            {props.match && props.match.params && props.match.params.sectionId ? <SectionsAdd sectionId={props.match.params.sectionId} /> : <SectionsList />}
            {redirect && <Redirect to={base_url+"/admin/login"}/>}
        </>
    );
}

export default Admin;